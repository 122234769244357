
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import { required } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import { Prop } from "vue-property-decorator";
    import * as toastr from "toastr";

    import { Authentication } from "@/stuff/Authentication";
    import apiClient from "@/stuff/ApiClient";
    import { UserStatus } from "@/model/Enums";
    import { User } from "@/model/User";
    import { ButtonToggler } from "@/stuff/ButtonToggler";
    import utils from "@/stuff/Utils";
    import { PasswordChangeRequest } from "@/model/PasswordChangeRequest";

    interface IPasswordCheckResult {
        isOkay: boolean;
        message: string;
    }

    @Component({
        components: { ApiButton }
    })
    export default class ChangePasswordDialogue extends Vue {

        //
        // -- properties
        //

        // If user is changing their _own_ password, they need to enter the old one
        // but if if this is in the admin section, user will be set and there's no need to know the old password
        // (seems that prop values passed as HTML attributes are always strings)
        @Prop({ default: "true" })
        shouldShowExisting!: string;

        @Prop({ default: "false" })
        shouldShowName!: string;

        @Prop({ required: true })
        user!: User;

        // boolean alias for string-typed prop (https://stackoverflow.com/questions/42715136/invalid-prop-type-check-failed-for-prop)
        get isShouldShowExisting(): boolean {
            return this.shouldShowExisting.toLowerCase() === "true";
        }

        get isShouldShowName(): boolean {
            return this.shouldShowName.toLowerCase() === "true";
        }

        passwordChange = new PasswordChangeRequest();

        invalidPasswordMessage: string = "";

        show() {
            utils.resetObject(this.passwordChange);
            this.$v.$reset();
            this.$bvModal.show("changePasswordDialogue");
        }

        hide() {
            this.$bvModal.hide("changePasswordDialogue");
            utils.resetObject(this.passwordChange);
        }

        async save(event: Event) {

            // validation goes off to server here - so show button spinner
            // (see buyer for non-async approach)
            const button = ButtonToggler.getButton(event);
            ButtonToggler.disableButton(button);
            if (!await this.isValid()) {
                toastr.info("Please fix the highlighted errors");
                ButtonToggler.enableButton(button);
                return;
            }
            ButtonToggler.enableButton(button); // if we don't re-enable now, css is wrong when we try and re-enable later

            // signed-in user or other user?
            this.passwordChange.userID = this.user 
                ? this.user.id 
                : Authentication.signedInUser()?.id || 0;

            const response = await apiClient.post("/api/user/changePassword", this.passwordChange, event); 

            if (response.message === "ok") {
                toastr.success("Changed Password");
                this.user.status = UserStatus.HasChangedPassword; // stop prompting...(do we need to update local storage?)
                this.hide();
            }
            else {
                //TODO - figure this out 
                alert(response.error);
            }            
        }

        //
        // -- validation
        //

        // Gahh! Apparently you can't debounce or throttle a validator function...
        // https://github.com/vuelidate/vuelidate/issues/242
        // (seems it might be in a future release)

        //private validatePassword = utils.debounce(async (password: string) => {
        //    const response: IPasswordCheckResult = await apiClient.get("/api/user/validatePassword?password=" + encodeURIComponent(password));
        //    this.invalidPasswordMessage = response.message;
        //    return response.isOkay;
        //}, 200);

        private async validatePassword(password: string): Promise<boolean> {
            const response: IPasswordCheckResult = await apiClient.get("/api/user/validatePassword?password=" + encodeURIComponent(password));
            this.invalidPasswordMessage = response.message;
            return response.isOkay;
        }

        private async checkExisting(password: string): Promise<boolean> {
            if (!this.isShouldShowExisting) return true;
            const response: IPasswordCheckResult = await apiClient.get("/api/authentication/verifyPassword?password=" + encodeURIComponent(password));
            this.invalidPasswordMessage = response.message;
            return response.isOkay;
        }

        @Validations()
        validations() {
            const validations = {
                passwordChange: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.passwordChange.existingPassword = { checkExisting: this.checkExisting };
            validations.passwordChange.newPassword = { required, validatePassword: this.validatePassword };
            validations.passwordChange.repeatPassword = {
                passwordsMatch: (value: string, model: PasswordChangeRequest) => {
                    return model.newPassword === model.repeatPassword;
                }
            };
            return validations;
        }

        // from https://github.com/vuelidate/vuelidate/issues/179
        private async isValid(): Promise<boolean> {
            this.$v.$reset();
            this.$v.$touch();
            await this.waitForValidation();
            return Promise.resolve(!this.$v.$error);
        }

        // from https://github.com/vuelidate/vuelidate/issues/179
        private waitForValidation() {
            return new Promise<void>(resolve => {
                if (this.$v.$error || !this.$v.$pending) {
                    return resolve();
                }
                const poll = setInterval(() => {
                    if (!this.$v.$pending) {
                        clearInterval(poll)
                        resolve()
                    }
                }, 200);
            })
        }
    }
