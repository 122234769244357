
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Watch } from "vue-property-decorator";
    import { Validations } from "vuelidate-property-decorators";
    import { email, required } from "vuelidate/lib/validators";
    import ApiButton from "@/components/ApiButton.vue";
    import ChangePasswordDialogue from "@/components/ChangePasswordDialogue.vue";
    import { User, IUser } from "@/model/User";
    import { UserRole } from "@/model/Enums";
    import { ILookupItem, LookupItem } from "@/model/LookupItem";
    import { RegionLookupItem } from "@/model/RegionLookupItem";
    import { IEmailDomain } from "@/model/EmailDomain";
    import store from "@/store/store";
    import apiClient from "@/stuff/ApiClient";
    import { ButtonToggler } from "@/stuff/ButtonToggler";
    import utils from "@/stuff/Utils";
    import * as toastr from "toastr";

    interface IEmailIsInUseResponse {
        isInUse: boolean;
    }

    @Component({
        components: {
            ApiButton, ChangePasswordDialogue
        }
    })
    export default class UserDialogue extends Vue {

        //
        // -- lifecycle hooks (https://vuejs.org/v2/guide/instance.html#Instance-Lifecycle-Hooks)
        //
        
        async mounted() {
            store.dispatch("loadBuyerList");
            store.dispatch("loadSupplierList");
            this.regionList = await apiClient.get("api/region/lookups");
            this.domainList = await apiClient.get("api/buyer/domains");
            this.moduleList = await apiClient.get("api/module/lookups");
        }

        //
        // -- properties
        //

        private readonly user = new User();
        private canChangeSupplier: boolean = true;
        private regionList: Array<RegionLookupItem> = [];
        private domainList: Array<IEmailDomain> = [];
        private moduleList: Array<ILookupItem> = [];

        // computed

        private get dialogueTitle(): string {
            if (!this.user) return "- - -";
            if (utils.isEmptyId(this.user.id)) return "New User";
            return "Edit " + this.user.fullname;
        }   
        
        private get isBuyerAdmin(): boolean { return store.getters.isBuyerMode; }
        private get buyerID(): number { return +this.$store.state.signedInUser.buyerID }

        private get roleList(): Array<ILookupItem> { return utils.enumToLookups(UserRole); }
        private get roleOptions(): Array<ILookupItem> { return utils.selectOptions(utils.enumToLookups(UserRole)); }

        private get buyerList(): Array<ILookupItem> { return this.$store.state.buyerList; }
        private get buyerOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.buyerList); }

        private get supplierList(): Array<ILookupItem> { return this.$store.state.supplierList; }
        private get supplierOptions(): Array<ILookupItem> { return utils.selectOptions(this.supplierList.filter(supplier => !supplier.isArchived)); }

        private get modulesLeft(): Array<ILookupItem> { 
            var halfSize = Math.ceil(this.moduleList.length / 2);
            return this.moduleList.slice(0, halfSize); 
        }
        private get modulesRight(): Array<ILookupItem> { 
            var halfSize = Math.ceil(this.moduleList.length / 2);
            return this.moduleList.slice(halfSize); 
        }

        private get regionOptions(): Array<ILookupItem> { 
            const filtered = this.regionList.filter(lu => lu.id > 0 && !lu.isArchived && lu.buyerID === this.user.buyerID);
            return filtered.length === 0 
                ? [ new LookupItem( { id: 0, description:  "(No regions for this buyer!)", isArchived: false }) ]
                : [ new LookupItem( { id: 0, description:  "Any region", isArchived: false }), ...filtered];
        }

        private get domainOptions(): Array<IEmailDomain> {
            return this.domainList.filter(ed => ed.buyerID == this.user.buyerID)
        }

        private get isEditingSelf() {
            if (!this.user) return false;
            if (!this.$store.state.signedInUser) return false;
            return this.user.id === this.$store.state.signedInUser.id;
        }

        private get emailSuffix(): string {
            return this.user.email.indexOf("@") > -1
                ? this.user.email.substring(0, this.user.email.indexOf("@"))
                : this.user.email;
        }
        private set emailSuffix(suffix: string) {
            this.user.email = `${suffix}${this.emailDomain}`;
        }

        private get emailDomain(): string {
            return this.user.email.indexOf("@") > -1
                ? this.user.email.substring(this.user.email.indexOf("@"))
                : "";
        }
        private set emailDomain(domain: string) {
            this.user.email = `${this.emailSuffix}${domain}`;
        }

        //
        // -- watchers
        //

        @Watch("user.role")
        private onUserRoleChanged() {
            if (!this.user.isCqmsOrSuperAdmin)
                this.user.isDormant = false;

            // todo: check buyer domains 
        }

        @Watch("user.buyerID")
        private onBuyerChanged() {
            this.emailDomain = "";

            if (this.domainOptions.find(d => d.default) != undefined) {
                this.emailDomain = this.domainOptions.find(d => d.default)!.domain;
            }
        }

        //
        // -- methods
        //

        private roleText(user: User): string {
            if(user.role === UserRole.Buyer) {
                if(user.isBuyerHmrcAccess && user.isBuyerAdminAccess) return "Buyer (HMRC + Admin)";
                if(user.isBuyerHmrcAccess) return "Buyer (HMRC)";
                if(user.isBuyerAdminAccess) return "Buyer (Admin)";
                return "Buyer";
            }
            else {
                return utils.lookupDescription(user.role, this.roleList);
            }
        }

        async edit(userData: IUser) {
            this.canChangeSupplier = true;
            this.user.update(userData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("userDialogue");

            //TODO - show waiting animation

            // strictly speaking we don't *have* to go to the server here - but data will be less stale if we do
            const serverUserData = await apiClient.get(`api/user/Load?id=${userData.id}`);

            this.user.update(serverUserData);
        }

        editNew() {
            this.canChangeSupplier = true;
            utils.resetObject(this.user);
            // role is hidden but we still need to set it - and the buyer ID!
            if(this.isBuyerAdmin) {
                this.user.role = UserRole.Buyer;
                this.user.buyerID = this.buyerID;
            }
            this.$v.$reset();
            this.$bvModal.show("userDialogue");
        }

        addSupplierUser(supplierID: number) {
            this.canChangeSupplier = false;
            utils.resetObject(this.user);
            // role is hidden but we still need to set it - and the buyer ID!
            this.user.role = UserRole.Supplier;
            this.user.supplierID = supplierID;
            this.$v.$reset();
            this.$bvModal.show("userDialogue");
        }

        private cancel() {
            this.$bvModal.hide("userDialogue");
            utils.resetObject(this.user);
        }

        // can be called from user edit dialogue OR from search results
        showChangePasswordDialogue(user?: User) {
            if(user) {
                this.user.update(user);
            }
            // tortured cast required becuase ChangePasswordDialogue is not a type as such but an alias for Vue type (somehow?)
            const dialogue = this.$refs.changePassword as ChangePasswordDialogue & { show: () => void };
            dialogue.show();
        }

        async save(event: Event) {

            // validation goes off to server here - so show button spinner
            // (see buyer for non-async approach)
            const button = ButtonToggler.getButton(event);
            ButtonToggler.disableButton(button);
            if (!await this.isValid()) {
            console.log("not valid...", this.$v);
                toastr.info("Please fix the highlighted errors");
                ButtonToggler.enableButton(button);
                return;
            }
            ButtonToggler.enableButton(button); // if we don't re-enable now, css is wrong when we try and re-enable later
            const response: { newID: number } = await apiClient.post("/api/user/save", this.user, event);
            if (this.user.isNew) {
                this.user.id = response.newID;
            }
            toastr.success("Saved");           
            this.$bvModal.hide("userDialogue");
            // redo search - or should we just update in place?
            await this.$emit("saved")
        }

        // 'delete' is a reserved word
        async deleteItem(event: Event) {
            if (this.user.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.user.fullname + "'?", {
                title: "Delete User",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/user/delete", this.user, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("userDialogue");
            await this.$emit("saved")
        }

        async restoreItem(event: Event) {
            if (this.user.isNew) return;

            const emailValid: boolean = await this.emailIsInUse(this.user.email);
            if (!emailValid) {
                toastr.error("Email address is already in use by another user")
                return;
            }

            const shouldRestore: boolean = await this.$bvModal.msgBoxConfirm("Do you want to restore '" + this.user.fullname + "'?", {
                title: "Restore User",
                okVariant: "primary",
                okTitle: "Yes, restore!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldRestore) return;
            await apiClient.post("/api/user/restore", this.user, event);
            toastr.warning("Restored");
            this.$bvModal.hide("userDialogue");
            await this.$emit("saved")
        }

        //
        // -- validation
        //

        // TODO - this should really be debounced but you can't (out of the box)
        // https://github.com/vuelidate/vuelidate/issues/242
        private async emailIsInUse(email: string) {
            if (utils.isEmptyOrWhitespace(this.user.email)) {
                return false;
            }

            const userID = this.user.isNew ? 0 : this.user.id;
            const response: IEmailIsInUseResponse = await apiClient.get("/api/user/emailIsInUse?email=" + encodeURIComponent(email) + "&userID=" + encodeURIComponent(userID));
            return !response.isInUse;
        }

        @Validations()
        validations() {
            const validations = {
                user: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.user.forename = { required };
            validations.user.surname = { required };
            validations.user.email = { required, email, isInUse: this.emailIsInUse };
            validations.user.role = { isNonZero: (value: number) => +value > 0 };
            validations.user.buyerID = { requiredIfBuyer: (value: number, user: IUser) => user.role !== UserRole.Buyer || +value > 0 };
            validations.user.supplierID = { requiredIfSupplier: (value: string, user: IUser) => user.role !== UserRole.Supplier || +value > 0 };

            return validations;
        }

        // from https://github.com/vuelidate/vuelidate/issues/179
        private async isValid(): Promise<boolean> {
            this.$v.$reset();
            this.$v.$touch();
            await this.waitForValidation();
            return Promise.resolve(!this.$v.$error);
        }

        // from https://github.com/vuelidate/vuelidate/issues/179
        private waitForValidation() {
            return new Promise(resolve => {
                if (this.$v.$error || !this.$v.$pending) {
                    return resolve(null);
                }
                const poll = setInterval(() => {
                    if (!this.$v.$pending) {
                        clearInterval(poll)
                        resolve(null)
                    }
                }, 200);
            })
        }
        
    }
