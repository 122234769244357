import { TriState, UserRole } from "./Enums";

export class UserSearchParameters {
    pageNumber: number = 0;
    searchText: string = "";
    role: UserRole = UserRole.None;
    email: string = "";
    isDeleted: TriState = TriState.UseDefault;
    supplierID: number = 0;
    buyerID: number = 0;
    buyerIDs: Array<number> = [];
    regionID: number = 0;
    isDormant: TriState = TriState.UseDefault;
    isAuditor: TriState = TriState.UseDefault;
    adminAuditUsers: TriState = TriState.UseDefault;
}